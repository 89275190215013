export function currency(value) {
  return "$" + parseFloat(value).toLocaleString()
}
export function currency_0(value) {
  return "$" + parseInt(value).toLocaleString()
}
export function percent(value) {
  return parseInt(value*100).toLocaleString()+"%"
}
export function percent_2(value) {
  return (parseFloat(value*10000) / 100).toLocaleString()+"%"
}
export function number_0(value) {
  return parseInt(value).toLocaleString()
}
export function number_1(value) {
  return parseFloat(value).toFixed(1).toLocaleString()
}
export function short_count(value) {
  if(value < 1000) {
    return value
  } else if(value < 1000000) { // k
    return parseFloat(value/1000).toFixed(1)+" k"
  } else if(value < 1000000000) { // m
    return parseFloat(value/1000000).toFixed(1)+" m"
  } else { // b
    return parseFloat(value/1000000000).toFixed(1)+" b"
  }
}
