import { defineAsyncComponent } from 'vue'

import Ad from './utils/ad.vue'
import Dropdown from './utils/dropdown.vue'
import Icon from './utils/icon.vue'
import Minicon from './utils/minicon.vue'

import NavInvest from './nav/nav-invest.vue'
import NavResearch from './nav/nav-research.vue'
import NavFire from './nav/nav-fire.vue'
import NavCalculator from './nav/nav-calculator.vue'
import NavAbout from './nav/nav-about.vue'
import NavUser from './nav/nav-user.vue'

import FormLogin from './forms/form-login.vue'
import FormMinafiMagazine from './forms/form-minafi-magazine.vue'
import FormMinimalInvestorSidebar from './forms/form-minimal-investor-sidebar.vue'
import FormMinimalInvestorCourse from './forms/form-minimal-investor-course.vue'
import FormMinimalInvestorCorner from './forms/form-minimal-investor-corner.vue'
import GdprConsent from './forms/gdpr-consent.vue'
import ActivityBadge from './posts/activity-badge.vue'
import Post from './posts/post.vue'
import ProgressScroll from './posts/progress-scroll.vue'
import SocialShares from './posts/social-shares.vue'

export default {
  // ./utils
  Ad: Ad,
  Dropdown: Dropdown,
  Flash: defineAsyncComponent({ loader: () => import('./utils/flash.vue') }),
  Icon: Icon,
  Minicon: Minicon,

  // ./nav
  NavInvest: NavInvest,
  NavResearch: NavResearch,
  NavFire: NavFire,
  NavCalculator: NavCalculator,
  NavAbout: NavAbout,
  NavUser: NavUser,

  // ./comments
  Comment: defineAsyncComponent({ loader: () => import('./comments/comment.vue') }),
  CommentForm: defineAsyncComponent({ loader: () => import('./comments/comment-form.vue') }),

  // ./courses
  CourseNavigation: defineAsyncComponent({ loader: () => import('./courses/course-navigation.vue') }),
  CourseToc: defineAsyncComponent({ loader: () => import('./courses/course-toc.vue') }),
  LessonComplete: defineAsyncComponent({ loader: () => import('./courses/lesson-complete.vue') }),
  LessonTask: defineAsyncComponent({ loader: () => import('./courses/lesson-task.vue') }),

  // ./forms
  FormLogin: FormLogin,
  FormMinafiJoin: defineAsyncComponent({ loader: () => import('./forms/form-minafi-join.vue') }),
  Contact: defineAsyncComponent({ loader: () => import('./forms/contact.vue') }),
  FormMinafiMagazine: FormMinafiMagazine,
  FormMinimalInvestorCourse: FormMinimalInvestorCourse,
  FormMinimalInvestorCorner: FormMinimalInvestorCorner,
  FormMinimalInvestorSidebar: FormMinimalInvestorSidebar,
  GdprConsent: GdprConsent,

  // // ./funds
  Fund: defineAsyncComponent({ loader: () => import('./funds/fund.vue') }),
  FundFeeComparisonChart: defineAsyncComponent({ loader: () => import('./funds/fund-fee-comparison-chart.vue') }),
  FundFeeComparisonBar: defineAsyncComponent({ loader: () => import('./funds/fund-fee-comparison-bar.vue') }),
  FundGraph: defineAsyncComponent({ loader: () => import('./funds/fund-graph.vue') }),
  FundSearch: defineAsyncComponent({ loader: () => import('./funds/fund-search.vue') }),

  // ./platforms
  Platform: defineAsyncComponent({ loader: () => import('./platforms/platform.vue') }),
  PlatformSearch: defineAsyncComponent({ loader: () => import('./platforms/platform-search.vue') }),
  PlatformFeeTable: defineAsyncComponent({ loader: () => import('./platforms/platform-fee-table.vue') }),
  PlatformFeesComparison: defineAsyncComponent({ loader: () => import('./platforms/platform-fees-comparison.vue') }),

  // ./payments
  Checkout: defineAsyncComponent({ loader: () => import('./payment/checkout.vue') }),

  // ./posts
  ActivityBadge:ActivityBadge,
  InteractivePost: defineAsyncComponent({ loader: () => import('./posts/interactive-post.vue') }),
  ModulaGallery: defineAsyncComponent({ loader: () => import('./posts/modula-gallery.vue') }),
  Post: Post,
  ProgressScroll: ProgressScroll,
  SocialShares: SocialShares,

  // ./viz
  AssetClassReturns: defineAsyncComponent({ loader: () => import('./viz/diversification/index.vue') }),
    DiversificationGrid: defineAsyncComponent({ loader: () => import('./viz/diversification/diversification-grid.vue') }),
    DiversificationGrowth: defineAsyncComponent({ loader: () => import('./viz/diversification/diversification-growth.vue') }),
    DiversificationRisk: defineAsyncComponent({ loader: () => import('./viz/diversification/diversification-risk.vue') }),
    DiversificationTable: defineAsyncComponent({ loader: () => import('./viz/diversification/diversification-table.vue') }),
  CalculatorCompoundInterest: defineAsyncComponent({ loader: () => import('./viz/calculator-compound-interest/index.vue') }),
  CalculatorExponentialGrowthCalculator: defineAsyncComponent({ loader: () => import('./viz/exponential-growth-calculator/index.vue') }),
    CalculatorExponentialGrowth: defineAsyncComponent({ loader: () => import('./viz/exponential-growth-calculator/index.vue') }), // Alias
  CalculatorFees: defineAsyncComponent({ loader: () => import('./viz/calculator-fees/index.vue') }),
  CalculatorRuleOf72: defineAsyncComponent({ loader: () => import('./viz/rule-of-72-calculator/index.vue') }),
  ChartPeriodicTableOfFire: defineAsyncComponent({ loader: () => import('./viz/periodic-table-of-fire/index.vue') }),
    PeriodicTableOfFireElement: defineAsyncComponent({ loader: () => import('./viz/periodic-table-of-fire/element.vue') }),
  CompoundInterest: defineAsyncComponent({ loader: () => import('./viz/compound-interest/index.vue') }),
  FireDate: defineAsyncComponent({ loader: () => import('./viz/fire-date/index.vue') }),
  GraphCompoundInterest: defineAsyncComponent({ loader: () => import('./viz/graph-compound-interest/index.vue') }),
  GraphInvestmentFees: defineAsyncComponent({ loader: () => import('./viz/graph-investment-fees/index.vue') }),
  GraphFireDate: defineAsyncComponent({ loader: () => import('./viz/graph-fire-date/index.vue') }),
  GraphSavingsRate: defineAsyncComponent({ loader: () => import('./viz/graph-savings-rate/index.vue') }),
  InvestmentFeesGraph: defineAsyncComponent({ loader: () => import('./viz/graph-investment-fees/index.vue') }),
  InvestmentFeesComparison: defineAsyncComponent({ loader: () => import('./viz/investment-fees-comparison/index.vue') }),
  Katex: defineAsyncComponent({ loader: () => import('./viz/katex/index.vue') }),
  SequenceOfAccounts: defineAsyncComponent({ loader: () => import('./viz/sequence-of-accounts/index.vue') }),
  RetirementCalculator: defineAsyncComponent({ loader: () => import('./viz/retirement-calculator/index.vue') }),
  ProgressCircle: defineAsyncComponent({ loader: () => import('./viz/progress-circle/index.vue') }),
  FireTax: defineAsyncComponent({ loader: () => import('./viz/fire-tax/index.vue') })

  // /// ./wip
  // Books
  // // Book: () => import('./wip/books/book'),
  // // Books: () => import('./wip/books/books')
  //
  // // ./quiz
  // Quiz: () => import('./wip/quiz/quiz.vue'),
  //   Question: () => import('./wip/quiz/question.vue'),
  //
  // HalfPieChart: () => import('./viz/half-pie-chart/index.vue')
}
