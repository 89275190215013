<template>
  <div class="dropdown--learn relative bg-blue-100 rounded md:shadow-lg md:mt-1">
    <icon icon='triangle' class='w-8 h-4 absolute top-0 text-blue-100 triangle hidden md:block'></icon>
    <div class="mb-2 md:px-4 md:py-2">
      <p class="text-blue-500 uppercase text-sm tracking-normal p-4 pb-2 font-bold">Knowledge Base</p>
      <ul class="list-reset">
        <li>
          <li class="flex-1">
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/funds">
              <minicon icon='landmark-regular' size='6' class='mr-3 text-blue-500'></minicon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-blue-800 pb-1">Mutual Fund &amp; ETF Directory</p>
                <p class="text-grey-800 text-sm font-400">Your funds for FI - organized.</p>
              </div>
            </a>
          </li>
          <li class="flex-1">
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-2 py-2 flex-row" href="/invest">
              <icon icon='phone-laptop-regular' class='w-8 h-6 mr-3 text-blue-500'></icon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-blue-800 pb-1">Apps &amp; Brokerages Directory</p>
                <p class="text-grey-800 text-sm font-400">Find the perfect place to invest.</p>
              </div>
            </a>
          </li>
        </li>
      </ul>
    </div>
    <div class="relative">
      <svg class="absolute" preserveAspectRatio="none" viewBox="0 0 2600 131.1">
        <path d="M0 0L2600 0 2600 100 0 0z" fill="#EFF5FF"></path>
      </svg>
      <div class="border-t rounded-t-none border-grey-400 bg-blue-200 md:px-4 md:py-2 rounded text-left">
        <p class="text-blue-500 uppercase text-sm tracking-normal p-4 pb-2 font-bold">Curated Content</p>
        <ul class="list-reset flex flex-col">
          <li class="flex-1">
            <a class="flex items-center no-underline rounded hover:shadow hover:bg-white px-3 py-2 flex-row" href="/funds?q%5Bname_or_symbol_cont%5D=&q%5Bfi_score_gteq%5D=90&q%5Bfi_score_lteq%5D=100&q%5Bexpense_ratio_gteq%5D=0&q%5Bexpense_ratio_lteq%5D=0.25&q%5Bmarket_in%5D%5B%5D=US+Stocks&q%5Bmarket_in%5D%5B%5D=International+Stocks&q%5Bmarket_in%5D%5B%5D=Bond">
              <minicon icon='book-regular' size='6' class='mr-3 text-blue-500'></minicon>
              <div class="flex-1 flex flex-col p-1">
                <p class="text-blue-800 pb-1">Top Funds by FI Score</p>
                <p class="text-grey-800 text-sm font-400">The best funds to index in for FI.</p>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import Minicon from 'Components/utils/minicon.vue'
import Icon from 'Components/utils/icon.vue'
export default {
  components: {
    Icon,
    Minicon
  }
}
</script>
