import { createPopperLite as createPopper, preventOverflow, flip } from '@popperjs/core'

function parsePlacement(modifiers) {
  if(modifiers['right']) {
    return 'right'
  } else if(modifiers['left']) {
    return 'left'
  } else if(modifiers['bottom']) {
    return 'bottom'
  } else if(modifiers['top']) {
    return 'top'
  } else {
    return 'right'
  }
}

function createTooltip(el, options) {
  // Wrapper
  var tooltip = document.createElement('div')
  tooltip.id = `tooltip-${parseInt(Math.random()*1000000000)}`
  tooltip.classList = `tooltip ${options.classes || ''}`
  tooltip.setAttribute('role', 'tooltip')
  tooltip.setAttribute('aria-hidden', 'true')

  var arrow = document.createElement('div')
  arrow.classList = 'tooltip-arrow'
  tooltip.append(arrow)

  var inner = document.createElement('div')
  inner.classList = 'tooltip-inner'
  if(options.html) {
    inner.innerHTML = `<p>${options.content}</p>`
  } else {
    inner.appendChild(document.createTextNode(options.content))
  }
  tooltip.append(inner)

  return tooltip
}

function SlimTooltip(el, binding, vnode) {
  if(el.getAttribute('tooltip-added')) { return }

  var options = binding.value

  // Default tooltip, no object
  if(typeof(options) != 'object') {
    options = {
      content: options,
      placement: parsePlacement(binding.modifiers)
    }
  }

  el.tooltip = false

  el.setAttribute('tooltip-added', true)
  el.addEventListener('mouseenter', (e) => {
    e.preventDefault()
    if(!el.tooltip) { el.tooltip = createTooltip(el, options) }
    if(!document.getElementById(el.tooltip.id)) {
      document.body.appendChild(el.tooltip)
      createPopper(el, el.tooltip, {
        placement: options.placement,
        modifiers: [preventOverflow, flip]
      })
    }
  })

  el.addEventListener('mouseleave', (e) => {
    e.preventDefault()
    var tip = document.getElementById(el.tooltip.id)
    if(tip) { tip.remove() }
  })

}
export default SlimTooltip
