<template>
  <ins class="adsbygoogle"
     style="display:block"
     :data-ad-client="client"
     :data-ad-slot="slot"
     :data-ad-format="format"
     :data-full-width-responsive="responsive"></ins>
</template>

<script>
const ADS = {
  'container': {
    client: 'ca-pub-9536487334502274',
    slot: '2422816266',
    format: 'auto',
    responsive: true
  },
  'vertical': {
    client: 'ca-pub-9536487334502274',
    slot: '9394229357',
    format: 'auto',
    responsive: true
  }
}

export default {
  props: {
    type: {
      type: String,
      required: true,
      default: 'container'
    }
  },
  data() {
    return {
      client: 'ca-pub-9536487334502274',
      slot: '2422816266',
      format: 'auto',
      responsive: true
    }
  },
  created() {
    let ad = ADS[this.type]
    if(ad) {
      this.client = ad.client
      this.slot = ad.slot
      this.format = ad.format
      this.responsive = ad.responsive
    }
  },
  mounted() {
    var adsbygoogle
    (adsbygoogle = window['adsbygoogle'] || []).push({});
  }
}
</script>
