<template>
  <div>
    <div class="progress-scroll p-bar bar shadow-md top-0 left-0 fixed h-1 z-50" v-if='show' :style='style' v-bind:class="completed ? 'bg-green-500' : 'bg-blue-500'"></div>

    <div class="p-wrapper w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  var lastValue;

  export default {
    name: 'progress-scroll',
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        started: false,
        scroll: 0,
        completedOnce: false
      }
    },
    computed: {
      ...mapFields({
        postFormat: 'post.post.format',
        progress: 'post.activity.progress',
        completed: 'post.activity.completed',
        user: 'user'
      }),
      style() {
        if(this.postFormat == 'text') {
          if(this.scroll == 0) { return 'width: 0px;' }
          else { return 'width:' + this.scroll + '%'; }
        } else {
          if(this.progress == 0) { return 'width: 0px;' }
          else { return 'width:' + this.progress + '%'; }
        }
      }
    },
    mounted() {
      // we get nextTick so  that we are sure that DOM is fully loaded
      this.$nextTick(function() {
        var theElement = document.getElementsByClassName('p-wrapper')[0];
        var theBar = document.getElementsByClassName('p-bar')[0];

        // attaching a EventListener for scrolling
        window.addEventListener('scroll', () => {
          var top = theElement.getBoundingClientRect().top - (window.innerHeight / 2);
          var height = theElement.getBoundingClientRect().height;
          var calculatedValue = Math.abs(top) / height * 100;
          // console.log('theElement.getBoundingClientRect().top ', theElement.getBoundingClientRect().top, ' window.innerHeight ', window.innerHeight, ' top ', top, ' calculatedValue ', calculatedValue, ' offsetTop ', theElement.offsetTop);

          if(this.postFormat == 'text') {
            if (theElement.getBoundingClientRect().top < 0) {
              if(calculatedValue > 100) { calculatedValue = 100; }
              this.scroll = calculatedValue;
              // this.style = 'width:' + calculatedValue + '%';
            } else {
              this.scroll = 0
              // this.style = 'width: 0px';
            }
          }

          if(calculatedValue == Infinity) {
            return
          } else if(calculatedValue > 0 && !this.started) {
            this.started = true;
            this.$store.dispatch('post/started')
          } else if((this.postFormat == 'text') && (calculatedValue >= 100 && !this.completed && !this.completedOnce)) {
            this.completedOnce = true
            this.$store.dispatch('post/progress', { progress: 100 })
          }

          if(lastValue != calculatedValue) {
            this.$store.dispatch('post/scroll', calculatedValue)
            lastValue = calculatedValue
          }
        }); // scroll
      }); // nextTick

    }
  }
</script>

<style>
.progress-scroll {
  transition: width 0.4s;
}
</style>
