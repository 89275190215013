import VGuest from 'Directives/guest'
import VLightbox from 'Directives/lightbox'
import VRefreshImages from 'Directives/refreshImages'
import VTooltip from 'Directives/tooltip'

export default {
  guest: VGuest,
  lightbox: VLightbox,
  refreshImages: VRefreshImages,
  tooltip: VTooltip
}
