import { isArray } from './isArray'
import { any } from './any'

function areElementsColiding(rect1, rect2) {
  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  );
}

export function areElementsColliding(element, collisionElements) {
  if(!isArray(collisionElements)) {
    collisionElements = [collisionElements]
  }

  var bounds = element.getBoundingClientRect()
  return any(collisionElements, (el) => areElementsColiding(el.getBoundingClientRect(), bounds))
}
