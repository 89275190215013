<template>
  <div class='card bg-gradient-to-b from-white via-white to-green-100 sidebar' v-if='!completed && user.guest'>
    <div class='text-center'>

      <div>
        <div class='flex flex-col items-center p-4 pb-2'>
          <p class='reset text-2xl font-black text-grey-800'>The Minimal Investor</p>
          <div class='w-1/3 lg:my-4'>
            <minicon icon='envelope-regular' size='12' class="text-green-500"></minicon>
          </div>
        </div>
      </div>

      <transition name='fade-up'>
        <div class='form-content' v-if='!submitted'>
          <p class='text-grey-700 text-base'>
            In this <b>free course</b>, you'll receive <br/>
            an <b>email a week for 10-weeks</b>.
          </p>

          <p class='text-grey-700 text-base mt-2'>
            By the end, you'll know how <br/>
            to invest <b>for the rest of your life</b>.
          </p>
          <form @submit.prevent='submit'>
            <div class='p-4'>
              <div class='field mb-6'>
                <label for='form-minimal-investor-course-email' class='sr-only'>Email</label>
                <input id='form-minimal-investor-course-email' type='email' placeholder="What's your email?" class='form-text' v-model='email' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.email'>{{errors.email}}</p>
              </div>
              <div class='field mb-6'>
                <label for='form-minimal-investor-course-name' class='sr-only'>Name</label>
                <input id='form-minimal-investor-course-name' type='text' class='form-text' placeholder="What's your name?" v-model='name' />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.name'>{{errors.name}}</p>
              </div>
              <div class='field flex content-center flex-row ml-1'>
                <input type='checkbox' id='mic-gdpr_consent' value='granted' v-model='gdpr_consent' class='mr-2 p-2 leading-tight transform scale-150 self-center mb-2' />
                <label for='mic-gdpr_consent' class='form-label ml-2' v-html='gdpr_consent_message'></label>
              </div>
              <p class='text-red-500 text-base text-left mt-1 mb-2' v-if='errors.consent'>{{errors.consent}}</p>
            </div>
            <div class=''>
              <button type='submit' class='button-green rounded rounded-t-none button-flex py-2 px-4 items-center justify-center' aria-label='Submit Form'>
                <minicon v-if='submitting' icon='spinner-regular' size='6' class="spin text-white flex-shrink"></minicon>
                <minicon v-else icon='envelope-regular' size='6' class="text-white flex-shrink"></minicon>
                <span class='flex-shrink ml-2 font-bold text-lg'>Learn to Invest For Free</span>
              </button>
            </div>
          </form>
        </div>

        <div v-else class='submitted-confirmation'>
          <div class='adam-head bg-green-100 mt-4 p-4 rounded rounded-t-none'>
            <p class='reset text-grey-800 mb-2 font-bold text-lg'>Thanks for signing up!</p>
            <p class='reset text-grey-700 text-lg text-center leading-tight font-semibold'>
              Confirm your email to receive the first lesson.
            </p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import Minicon from 'Components/utils/minicon.vue'
import { post } from 'App/client'

export default {
  props: ['source'],
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      'subscriptions': 'user.subscriptions',
      'user': 'user'
    }),
    completed() {
      return (this.subscriptions.indexOf('minimalinvestor') !== -1)
    }
  },
  data() {
    return {
      email: '',
      name: '',
      gdpr_consent: false,
      gdpr_consent_message: "I consent to Minafi's <a href='/privacy-policy' class='link link-green' target='_blank'>privacy policy</a>.",
      submitting: false,
      submitted: false,
      errors: {
        name: null,
        email: null,
        consent: null
      }
    }
  },
  mounted() {
    if(this.user && !this.user.guest) {
      this.name = this.user.name
    }
  },
  methods: {
    submit() {
      if(!this.valid()) { return; }

      // Magic to submit this
      this.submitting = true
      this.$el.classList.add('form-submitting')

      post('/account/subscribe', {
        subscription: 'minimalinvestor',
        source: this.source,
        fields: {
          email: this.email,
          name: this.name,
          gdpr: this.gdpr_consent,
          consent_message: this.gdpr_consent_message
        }
      }).then((response) => {

        post('/account/subscribe', {
          subscription: 'newsletter',
          send_in: 60,
          fields: {
            email: this.email,
            name: this.name,
            gdpr: this.gdpr_consent,
            consent_message: this.gdpr_consent_message,
            silent: true
          }
        }).then((response) => {
          this.submitting = false
          if(response.message) {
            this.errors.consent = "Uh oh! There was a problem. Could you give it another try?"
          } else {
            this.submitted = true
            this.$el.classList.remove('form-submitting')
            this.$el.classList.add('form-submitted')
          }
        })
      })
    },
    valid() {
      if(!this.name) {
        this.errors.name = 'Please enter a user name.'
      } else {
        this.errors.name = null
      }

      if(!this.validEmail(this.email)) {
        this.errors.email = 'Please enter a valid email address.'
      } else {
        this.errors.email = null
      }

      if(!this.gdpr_consent) {
        this.errors.consent = 'You really need to agree to these terms. Legal people love them.'
      } else {
        this.errors.consent = null
      }

      return !(this.errors.email ||  this.errors.name ||  this.errors.consent)
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>
