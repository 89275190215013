<template>
  <div class='social-shares top-1/3 flex mx-auto flex-row lg:flex-col lg:fixed lg:left-0 h-50p items-center
  transition-all duration-300 justify-center px-2' v-bind:class="{ 'opacity-0 -ml-8': isUnstarted, 'opacity-1 ml-8': !isUnstarted, 'progress-completed': isCompleted, 'progress-in-progress': isInProgress, 'opacity-0': colliding }">
    <div class='social-shares--total text-grey-700 lg:mb-6 flex flex-row lg:flex-col items-center' v-if='total_views_count > minViewsCount'>
      <minicon icon='share-alt-solid' size='6' class='flex-1 mr-2 lg:mb-2 inline'></minicon>
      <span class='text-2xl flex-1 text-grey-800 mr-2 lg:mr-0'>{{total_views_count_text}}</span>
      <span class='text-lg flex-1 text-grey-dark'>reads</span>
    </div>
    <div v-else class='social-shares--total lg:mb-6 text-grey-500 p-2'>
      <minicon icon='share-alt-solid' size='4' class='flex-1 lg:mb-2 inline'></minicon>
    </div>

    <a class='text-center rounded-sm w-12 p-2 block hover:bg-facebook text-center text-grey-700 hover:text-white no-underline' href='#' @click.prevent='shareOnFacebook'>
      <minicon icon='facebook-square-brands' size='6'></minicon>
    </a>

    <a class='text-center rounded-sm w-12 p-2 block hover:bg-twitter text-center text-grey-700 hover:text-white no-underline' href='#' @click.prevent='shareOnTwitter'>
      <minicon icon='twitter-brands' size='6'></minicon>
    </a>

    <a class='text-center rounded-sm w-12 p-2 block hover:bg-pinterest text-center text-grey-700 hover:text-white no-underline' href='#' @click.prevent='shareOnPinterest'>
      <minicon icon='pinterest-brands' size='6'></minicon>
    </a>

    <activity-badge class='p-2 block mt-1'></activity-badge>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { short_count } from 'App/numeral'
import { areElementsColliding } from 'App/utils/areElementsColliding'
import { throttle } from 'App/utils/throttle'
import { scrollStart } from 'App/utils/scrollStart'

import ActivityBadge from 'Components/posts/activity-badge.vue'
import Minicon from 'Components/utils/minicon.vue'

export default {
  components: {
    ActivityBadge,
    Minicon
  },
  data() {
    return {
      minViewsCount: 1000,
      colliding: false
    }
  },
  computed: {
    ...mapFields({
      total_views_count: 'post.post.activities_count',
      scroll: 'post.scroll',
      action: 'post.action',
      post: 'post.post',
    }),
    total_views_count_text() {
      var views = this.total_views_count
      if(views >= this.minViewsCount) {
        return short_count(views)
      } else {
        return views
      }
    },
    isUnstarted() {
      return this.action == 'initialized'
    },
    isInProgress() {
      return (this.scroll > 0) && (this.scroll < 75)
    },
    isCompleted() {
      return this.action == 'completed'
    }
  },
  mounted() {
    scrollStart(this.setupCollisionDetection)
  },
  methods: {
    setupCollisionDetection() {
      window.addEventListener('scroll', throttle(this.checkForCollisions, 250))
      // we get nextTick so  that we are sure that DOM is fully loaded
      var postContent = document.getElementsByClassName('content-wrapper')[0]
      this.collisionElements = []
      if(postContent) {
        this.collisionElements = this.collisionElements.slice.call(postContent.getElementsByTagName('*'))
      }

      // Todo
      // for(var cEl of document.getElementsByClassName('main--section')) {
      //   this.collisionElements.push(cEl)
      //   this.collisionElements.concat([].slice.call(cEl.getElementsByTagName('*')))
      // }
    },
    checkForCollisions(e) {
      this.colliding = areElementsColliding(this.$el, this.collisionElements)
    },

    shareOnFacebook() {
      var shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${escape(this.post.url)}&t=${this.post.title}`
      window.open(shareUrl, 'socialShareFacebook', 'height=300, width=600, top=50, left=50, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
    },

    shareOnTwitter() {
      var shareUrl = `https://twitter.com/share?url=${escape(this.post.url)}&text=${this.post.title} via @minafiblog`
      window.open(shareUrl, 'socialShareTwitter', 'height=300, width=600, top=50, left=50, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
    },

    shareOnPinterest() {
      var description = `${this.post.title} - ${this.post.excerpt}`
      var shareUrl = `https://pinterest.com/pin/create/link/?url=${escape(this.post.url)}&description=${description}`
      window.open(shareUrl, 'socialSharePinterest', 'height=300, width=600, top=50, left=50, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
    }
  }
}
</script>
