<template>
  <svg class="inline-block"><use v-bind:xlink:href='"#" + icon' /></svg>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    }
  }
}
</script>
